.label {
    width: 100%;
}

.card-input-element {
    display: none;
}

.card-input {
    margin: 10px;
    padding: 00px;
}

.card-input:hover {
    cursor: pointer;
}

.card-input-element:checked + .card-input {
    box-shadow: 0 0 1px 1px #005b8f;
}

.light-color {
    color: #757575;
}
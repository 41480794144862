.vertical-alignment-helper {
    display: table;
    height: 100%;
    width: 100%;
    pointer-events: none;
}

.vertical-align-center {
    /* To center vertically */
    display: table-cell;
    vertical-align: middle;
    pointer-events: none;
    width: 70%;
}

.modal-content {
    /* Bootstrap sets the size of the modal in the modal-dialog class, we need to inherit it */
    width: inherit;
    /* For Bootstrap 4 - to avoid the modal window stretching full width */
    max-width: inherit;
    height: inherit;
    /* To center horizontally */
    margin: 0 auto;
    pointer-events: all;
}

.modal-body {
    padding: 0 !important;
}

.body-container {
    min-height: 180px;
}